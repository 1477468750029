import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import ConfigContextProvider from '../contexts/config';

import { init, firebase } from 'fired-up-core/src/library/firebase';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import withReduxStore from '../stores/with-redux-store';

import theme from '../styles/theme';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

class MyApp extends App {
  static async getInitialProps({ ctx }) {
    const server_rendered = !!ctx.req;

    const firebase_config =
      ctx.req && ctx.req.firebase_config ? ctx.req.firebase_config : null;

    const app_config = ctx?.req?.appConfig || null;

    return {
      app_config,
      firebase_config,
      server_rendered,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      configSet: false,
    };
  }

  componentDidMount() {
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (this.props.firebase_config && this.props.server_rendered) {
      init(this.props.firebase_config, () => {
        this.setState({
          configSet: true,
        });
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });
    }

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;
    if (
      typeof window !== 'undefined' &&
      process.env.NODE_ENV === 'development'
    ) {
      window.theme = theme;
    }

    if (this.props.server_rendered && !this.props.firebase_config) {
      return <div>Site not found.</div>;
    }

    if (!this.state.configSet) {
      return null;
    }

    return (
      <ConfigContextProvider
        appConfig={{ ...this.props.app_config }}
        csConfig={{ ...this.props.firebase_config }}
      >
        <Provider store={reduxStore}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Component {...pageProps} />
          </ThemeProvider>
        </Provider>
      </ConfigContextProvider>
    );
  }
}

export default withReduxStore(MyApp);
