export const colors = {
  blue: '#1322a8',
  orange: '#f05b3a',
  'black-1': '#4a4a4a',
  'color-gray-light': '#F1F1F2',
  'color-gray-background': '#f5f6f9',
  'color-gray-extra-light': '#f9f9f9',
  'table-header-gray': '#fafafa',
  matchingBlue: '#1D68D9',
  matchingGreen: '#49BF78',
  white: '#FFFFFF',
  red: '#D70000'
};

export const fonts = {
  sansSerif: 'Centra No.2,Helvetica,Myriad Pro,Roboto,arial,sans-serif',
  monospace:
    'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
};

export const shared = {
  matchingBlock: theme => ({
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    borderRadius: '6px',
    boxShadow: `0px 0px 14px rgba(0, 0, 0, 0.04)`,
    backgroundColor: 'white',
  }),
  button: {
    padding: '10px 15px',
    backgroundColor: colors.matchingBlue,
    border: 'none',
    borderRadius: '6px',
    color: '#fff',
    fontSize: '14px',
    // https://stackoverflow.com/a/48087031/628699
    fontWeight: 'bold' as 'bold',
    textAlign: 'center' as 'center',
    cursor: 'pointer',
  },
  buttonOutline: {
    color: colors.matchingBlue,
    backgroundColor: '#fff',
    border: `1px solid ${colors.matchingBlue}`,
    borderRadius: '6px',
    fontWeight: 'bold' as 'bold',
    textAlign: 'center' as 'center',
    cursor: 'pointer',
  },
};
