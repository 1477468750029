import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import FirestoreTypes from '@firebase/firestore-types';

let firestore: FirestoreTypes.FirebaseFirestore;

function init(firebase_config, callback) {
  if (typeof firebase.initializeApp === 'function' && !firebase.apps.length) {
    firebase.initializeApp(firebase_config);
    firestore = firebase.firestore() as FirestoreTypes.FirebaseFirestore;
    typeof callback === 'function' && callback();
  }
}

export { firebase, firestore, init };

export default firebase;
